import React, { useState } from 'react';
import axios from 'axios';

// ANTD
import { Button, Collapse, Form, Input, Modal } from 'antd';

// STYLES
import './styles.scss';

const { Panel } = Collapse;
const { TextArea } = Input;

export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

function Faq() {
    
    const [form] = Form.useForm();
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isContactModalOpen, setIsContactlModalOpen] = useState(false);
    const [loading, setLoadingState] = useState<boolean>(false);
    const [success, setSuccessState] = useState<boolean>(false);
    const [error, setErrorState] = useState<boolean>(false);

    const showModal = (): void => {
        setIsModalOpen(true);
    };

    const handleOk = (): void => {
        setIsModalOpen(false);
    };

    const handleCancel = (): void => {
        setIsModalOpen(false);
    };

    const showContactModal = (): void => {
        setIsContactlModalOpen(true);
    };

    const handleContactOk = (): void => {
        setIsContactlModalOpen(false);
    };

    const handleContactCancel = (): void => {
        setIsContactlModalOpen(false);
    };

    const sendContactForm = async (): Promise<void> => {

        setLoadingState(true);

        const formData = new FormData();

        formData.append('fullName', form.getFieldValue('fullName'));
        formData.append('email', form.getFieldValue('email'));
        formData.append('subject', form.getFieldValue('subject'));
        formData.append('message', form.getFieldValue('message'));

        try {

            const formResponse = await  axios.post('https://hooks.zapier.com/hooks/catch/1247261/3y9elyg/', formData);

            setLoadingState(false);
            setSuccessState(true);

            setTimeout(() => {
                handleContactOk();
            }, 3000);

        } catch (error) {

            setErrorState(true);

            setTimeout(() => {
                handleContactOk();
            }, 3000);

        }

    };

    return (
        <>
            <div className="faq-container">
                <div className="faq-content">
                    <h1>FAQ</h1>
                    <Collapse ghost>
                        <Panel header="When is the deadline?" key="1">
                            <p>Sunday, March 12th 2023</p>
                        </Panel>
                        <Panel header="What are the entry requirements?" key="2">
                            <ul>
                                <li>5-20 full looks inspired by iconic Dolce&Gabbana prints</li>
                                <li>Digital design in any format, and with any medium is accepted</li>
                                <li>Link to the art portfolio containing the submissions (wetransfer, dropbox, google drive, etc.)</li>
                            </ul>
                        </Panel>
                        <Panel header="Do I need to have any experience in the fashion world to submit a portfolio?" key="3">
                            <p>No. There is no requirement for previous experience or education.</p>
                        </Panel>
                        <Panel header="Will the selection be based on my portfolio alone, or will it also be based on my social media following, education, and experience?" key="4">
                            <p>The selection will be solely based on the portfolio that’s been submitted. We will only be taking into consideration portfolios that adhere to the entry requirements.</p>
                        </Panel>
                        <Panel header="What are the Dolce&Gabbana prints that have to be used as inspiration?" key="5">
                            <p>Leopard, Zebra, the traditional Sicilian Carretto, Blue Mediterranean, the famous DG logos, and quintessential black lace.</p>
                        </Panel>
                        <Panel header="What sort of mentorship will be provided by the brand?" key="6">
                            <p>Dolce&Gabbana will work together with the finalists to give creative feedback on their submitted designs, and help refine the final looks. These final designs will form part of the Future Rewind digital collection that will be airdropped to DGFamily holders.</p>
                        </Panel>
                        <Panel header="If my designs are selected, who will have IP rights to them? By submitting my designs, am I signing over IP rights?" key="7">
                            <p>All final designs will be owned by Dolce&Gabbana and UNXD. Dolce&Gabbana and UNXD reserve the rights to all final digital assets of the winning designs. Finalists will be credited with co-creation wherever applicable. You can read more about this in <span className="terms-conditions" onClick={() => showModal()}>the terms & conditions.</span></p>
                        </Panel>
                        <Panel header="Will I receive any monetary benefit from royalties for secondary sales?" key="8">
                            <p>50% of all royalties will be equally split between the finalists based on their number of winning designs. You can read more about this in <span className="terms-conditions" onClick={() => showModal()}>the terms & conditions.</span></p>
                        </Panel>
                        <Panel header="Is there a possibility of future work or partnerships with the brand following this competition?" key="9">
                            <p>There is no explicit guarantee of a future partnership as part of winning this competition, however Dolce&Gabbana, UNXD, and Decentraland are continuously looking to collaborate with and champion young talent, and will likely partner with up and coming artists in future initiatives too.</p>
                        </Panel>
                        <Panel header="Will my name be on the winning designs?" key="10">
                            <p>Yes, all winning designers will be credited wherever applicable.</p>
                        </Panel>
                        <Panel header="What file type, resolution, etc would you like submissions to be in?" key="11">
                            <p>We accept applications from any design software and in any format. Submissions need to be made through a portfolio link.</p>
                        </Panel>
                        <Panel header="When will finalists be notified?" key="12">
                            <p>Final winners will be announced during Metaverse Fashion Week, March 28-31, 2023</p>
                        </Panel>
                        <Panel header="Will Dolce&Gabbana’s social accounts be posting about the competition?" key="13">
                            <p>Yes, Dolce&Gabbana official Twitter and Instagram accounts will be posting the winning designs, and all finalists will be tagged in the social posts.</p>
                        </Panel>
                        <Panel header="Do I need to be over 18 to enter?" key="14">
                            <p>Yes, participants in the competition must be 18 or older to enter.</p>
                        </Panel>
                        <Panel header="I have more questions!" key="15">
                            <p>For any other questions regarding the competition or your submission <span className="terms-conditions" onClick={() => showContactModal()}> please contact Dolce&Gabbana</span> with the subject line “Future Rewind Competition”</p>
                        </Panel>
                    </Collapse>
                </div>
            </div>
            <Modal className="terms-conditions-modal" title="TERMS AND CONDITIONS" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <p>By registering for the Future Rewind Competition presented by Dolce&Gabbana and UNXD, I hereby agree to be bound by the following terms and conditions (the “Terms and Conditions”):
        I shall fully participate in all Future Rewind Competition events and related programs and, upon reasonable request, will provide any additional images or materials required for use for the Future Rewind Competition and any accompanying public materials, exhibitions, or related programs. I acknowledge that opting out of any components of the Future Rewind program may be grounds for disqualification.</p>
                <p>UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana, shall have irrevocable, perpetual, exclusive permission to use and modify the materials I submit to the Future Rewind Competition (the “Materials”), in whole or in part, in any manner or media (whether now known or later developed) for purposes of the Future Rewind Competition, the subsequent digital collection, promotion thereof, and for all standard educational, scholarly, research, or archival uses.</p>
                <p>UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana shall have perpetual and irrevocable permission to use without charge my name, likeness, image(s), and video(s) in connection with the Future Rewind Competition, promotion thereof, and for all standard educational, research, or archival uses.</p>
                <p>UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana will not return my Materials but instead may keep my Materials as part of the archives of the Future Rewind Competition.</p>
                <p><strong>Representation and Warranty:</strong> I represent and warrant that I own all rights to the Materials I submit or that I have obtained all permissions necessary from the respective owners to give the Future Rewind Competition the rights described above. Upon request, I promptly shall provide documentation of such permissions to the Future Rewind Competition. Failure to promptly provide such documentation may result in my disqualification at the sole discretion of the Future Rewind Competition.</p>
                <p><strong>Confidentiality:</strong> Information about my candidacy, my submission of the Materials, and any information disclosed to me about the Future Rewind Competition candidacy, registrations, candidate submissions, judging, and related processes, is proprietary to the Future Rewind Competition. I shall not disclose such information to any third party without prior written permission from UNXD or Dolce&Gabbana.</p>
                <p><strong>Publicity Release:</strong> UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana may photograph, film, or otherwise record my participation, performance, statements, and any of my Materials that appear in the Future Rewind Competition and related programs. The aforementioned parties may use such film or recording in any manner or medium (whether now known or later discovered) without charge. I disclaim any interest or rights I may have in connection with my appearance, participation, or statements for the Future Rewind Competition and related programs, as filmed or recorded or edited by UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana. The aforementioned parties are under no obligation to film or record me or my Materials or to use such film or record if made.</p>
                <p><strong>Liability Release:</strong> I irrevocably release and discharge UNXD and Dolce&Gabbana, and any associated companies, affiliates, or partners of the aforementioned from any liability or responsibility for any loss or damage to my Materials or any loss or damage that occurs as a result of my participation in the Future Rewind Competition.</p>
                <p><strong>Indemnity:</strong> I irrevocably indemnify and hold harmless UNXD and Dolce&Gabbana, and its collective affiliates, regents, trustees, officers, jury, employees, agents, and partners from and against all claims, demands, suits, judgments, and expenses, including reasonable out-of-pocket attorneys’ fees, arising out of my failure to comply with this Agreement or otherwise from my participation in the Future Rewind Competition.</p>
                <p><strong>Miscellaneous:</strong> UNXD and Dolce&Gabbana have no obligation to select me or my submission as a Finalist or a winner. UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana have the right but not the obligation to use my Materials, name, likeness, image, or participation in any manner. UNXD and Dolce&Gabbana may cancel or make adjustments to the Future Rewind Competition at any time and at its sole discretion. All decisions from UNXD and Dolce&Gabbana are final and binding.</p>
                <p>Any/all proprietary information provided to the Future Rewind Competition will be kept strictly confidential and will be specifically used in connection with the review of your application. The composition and financial amount of any associated prize will be made in the sole discretion of the Future Rewind Competition.</p>
                <p><strong>Agreement*</strong> I have read and agreed to the foregoing Terms and Conditions. I agree that the information contained in this application is true and correct to the best of my knowledge. I further certify that I, the undersigned applicant, have personally completed this application.</p>
            </Modal>
            <Modal className="contact-modal" title="CONTACT" open={isContactModalOpen} onOk={handleContactOk} onCancel={handleContactCancel} footer={null}>
                { success ? <div className="contact-message-container"><h1>Your message was sent!</h1></div> : null }
                { error ? <div className="contact-message-container"><h1>There was an error sending your message!</h1></div> : null }
                { !success && !error ? (
                    <Form form={form}>
                        <Form.Item name="fullName" rules={[{ required: true, message: "Full name is required!" }]}>
                            <Input size="large" placeholder="Full Name"></Input>
                        </Form.Item>
                        <Form.Item name="email" rules={[{ required: true, message: "Email not valid!", pattern: new RegExp(EMAIL_REGEX), type: 'email' }]}>
                            <Input size="large" placeholder="Email"></Input>
                        </Form.Item>
                        <Form.Item name="subject">
                            <Input size="large" placeholder="Subject"></Input>
                        </Form.Item>
                        <Form.Item name="message">
                            <TextArea rows={4}size="large" placeholder="Message"/>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button style={{ width: '100%' }} type="primary" size="large" onClick={() => sendContactForm()} disabled={!form.getFieldValue('fullName') || !form.getFieldValue('email') || !form.getFieldValue('subject') || !form.getFieldValue('message') || form.getFieldsError().filter(({ errors }) => errors.length).length > 0} loading={loading}>Submit</Button>
                            )}
                        </Form.Item>
                    </Form>
                ) : null }
            </Modal>
        </>
    );
}

export default Faq;