import React, { useEffect, useState } from 'react';

import './styles.scss';

function Footer() {

    return (
        <footer id="footer">
            <div className="container footer-container">
                <div className="footer-widgets">
                    <div className="footer-widget footer-widget-first">
                        <div className="footer-widget-logo">
                            <img src="../../assets/img/logo_light.png" width="111px" alt="UNXD Logo" />
                        </div>
                        <div className="footer-widget-text">
                            UNXD is a curated marketplace for the best of digital luxury and culture.
                        </div>
                    </div>
                    <div className="footer-widget footer-widget-nav hide-it-onmobile">
                        &nbsp;
                    </div>
                    <div className="footer-widget footer-widget-nav">
                        <div className="footer-widget-title">
                            Navigate
                        </div>
                        <ul className="footer-widget-nav-items">
                            <li className="footer-widget-nav-item">
                                <a href="https://unxd.com/drops" className="underline-animation underline-animation--footer">Drops</a>
                            </li>
                            <li className="footer-widget-nav-item">
                                <a href="https://unxd.com/about" className="underline-animation underline-animation--footer">About</a>
                            </li>
                            <li className="footer-widget-nav-item">
                                <a href="https://help.unxd.com/en/" className="underline-animation underline-animation--footer">Helpcenter</a>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-widget footer-widget-last">
                        <div className="footer-widget-title">
                            Newsletter
                        </div>
                        <div className="footer-widget-text">
                            Join our mailing list to stay in the loop with our newest feature releases, NFT drops, and
                            tips and tricks for navigating UNXD.
                        </div>
                        <div className="footer-widget-form">
                            <form className="subscribe-form form" action="" method="post">
                                <div className="form-row">
                                    <div className="form-col form-col-1">
                                        <div className="form-group">
                                            <div className="form-input">
                                                <span className="form-input-name">Email</span>
                                                <input type="email" name="email" placeholder="Email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-col form-col-0">
                                        <button type="button" className="button button-primary">Subscribe</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="footer-widget-social">
                            <div className="footer-widget-social-icons">
                                <div className="footer-widget-social-icon social-icon-twitter">
                                    <a href="https://twitter.com/unxd_nft" target='_blank' rel="noreferrer">
                                        <img src="../../assets/img/twitter-logo-light.svg" width="24px" height="24px" alt="Twitter logo" />
                                    </a>
                                </div>
                                <div className="footer-widget-social-icon social-icon-discord">
                                    <a href="https://discord.unxd.com/invitation" target='_blank' rel="noreferrer">
                                        <img src="../../assets/img/discord_white.svg" width="24px" height="24px" alt="Discord logo" />
                                    </a>
                                </div>
                                <div className="footer-widget-social-icon social-icon-instagram">
                                    <a href="https://www.instagram.com/unxd_nft/" target='_blank' rel="noreferrer">
                                        <img src="../../assets/img/instagram_white.svg" width="24px" height="24px" alt="Instagram logo" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-widget footer-widget-first footer-widget-empty"></div>
                    <div className="footer-widget footer-widget-nav">
                        <a href="https://unxd.com/privacy" className="underline-animation underline-animation--footer">Privacy</a>
                    </div>
                    <div className="footer-widget footer-widget-nav">
                        <a href="https://unxd.com/terms" className="underline-animation underline-animation--footer">Terms
                            of Service</a>
                    </div>
                    <div className="footer-widget footer-widget-last">
                        <a href="https://help.unxd.com/en/" className="underline-animation underline-animation--footer">Report a Bug</a>
                    </div>
                </div>
            </div>
        </footer>
    );

}

export default Footer;