import React from 'react';

// ANTD
import { Button } from 'antd';

// MODULEs
import Apply from '../Apply';
import Faq from '../Faq';

// STYLES
import './styles.scss';

function Home() {

    const applyNow = (): void => {
        document.getElementById('apply')?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="home-container">
            <div className="main-video-container">
                <video autoPlay={true} src="https://res.cloudinary.com/dvz8qqgob/video/upload/q_auto/v1675667508/UNXD/APPROVATO_Future-Rewind-LOGO_xnvrtv.mp4" loop={true} muted={true} playsInline={true}></video>
            </div>
            <div className="future-rewind-competition-container">
                <div className="future-rewind-competition-video">
                    <video autoPlay={true} src="https://res.cloudinary.com/dvz8qqgob/video/upload/q_auto/v1675667391/UNXD/5_x97oi4.mp4" loop={true} muted={true} playsInline={true}></video>
                </div>
                <div className="future-rewind-competition-content">
                    <p>UNXD and Dolce&Gabbana present:</p>
                    <h2>The Future Rewind<br/>Competition</h2>
                    <p>For Metaverse Fashion Week 2023, Dolce&Gabbana and UNXD are launching a digital wearable competition to shine a light on emerging talent. The Future Rewind Competition marks the first time a traditional luxury brand has offered direct and personal mentorship in empowering a new generation of digital creators.</p>
                    <p>The competition aims to create opportunities for an exchange of ideas and visions across multiple generations and mediums.</p>
                    
                </div>
            </div>
            <div className="write-history-container">
                <div className="write-history-content">
                    <h2>Competition details</h2>
                    <p>Dolce&Gabbana and UNXD invite participants to take a deep dive into the Dolce&Gabbana universe and incorporate elements of the brand’s most iconic designs into their submissions.</p>
                    <p>To enter, each contestant will have to submit a minimum of 5, and maximum of 20 full digital looks, inspired by any one, or any combination of these iconic Dolce&Gabbana prints: <strong>Leopard, Zebra, the traditional Venice Carretto, Blue Mediterranean, the famous DG logos, and quintessential black lace.</strong></p>
                    <div className="write-history-apply-now">
                        <Button type="primary" size="large" onClick={() => applyNow()}>Apply now</Button>
                    </div>
                </div>
                <div className="write-history-video">
                    <video autoPlay={true} src="https://res.cloudinary.com/dvz8qqgob/video/upload/q_auto/v1675667505/UNXD/13_ac0337.mp4" loop={true} muted={true} playsInline={true}></video>
                </div>
            </div>
            <div className="inclusive-network-container">
                <div className="inclusive-network-content">
                    “Creating an inclusive network to support young talent has been an important part of our philosophy for decades, and we are incredibly excited to extend that mentorship into the metaverse with the Future Rewind competition.”<br/>– Domenico Dolce & Stefano Gabbana
                </div>
            </div>
            <div className="write-history-container">
                <div className="write-history-content">
                    <h2>Co-write history with us</h2>
                    <p>The finalists will be able to showcase their creations at the dedicated Dolce&Gabbana space in Decentraland during Metaverse Fashion Week. A jury, which will include Mr. Dolce and Mr. Gabbana, alongside a public vote from the DGFamily holders, will be involved in selecting the winning pieces.</p>
                    <p>A final 15-20 pieces will be selected to create a digital wearable collection, co-branded with Dolce&Gabbana and airdropped exclusively as digital wearables to the DGFamily holders.</p>
                </div>
                <div className="write-history-video">
                    <video  autoPlay={true} src="https://res.cloudinary.com/dvz8qqgob/video/upload/q_auto/v1675855836/UNXD/4_rq65c8.mp4" loop={true} muted={true} playsInline={true}></video>
                </div>
            </div>
            <Apply/>
            <Faq/>
        </div>
    );

}

export default Home;


