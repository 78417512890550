import React, { useState } from 'react';
import axios from 'axios';

// ANTD
import { Button, Checkbox, Form, Input, Select, Modal } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

// STYLES
import './styles.scss';

export const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

function Apply() {

  const [form] = Form.useForm();

  const [loading, setLoadingState] = useState<boolean>(false);
  const [success, setSuccessState] = useState<boolean>(false);
  const [error, setErrorState] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
      setIsModalOpen(true);
  };

  const handleOk = () => {
      setIsModalOpen(false);
  };

  const handleCancel = () => {
      setIsModalOpen(false);
  };

  const sendForm = async () => {

    setLoadingState(true);

    const formData = new FormData();

    formData.append('firstName', form.getFieldValue('firstName'));
    formData.append('lastName', form.getFieldValue('lastName'));
    formData.append('email', form.getFieldValue('email'));
    formData.append('twitter', form.getFieldValue('twitter'));
    formData.append('instagram', form.getFieldValue('instagram'));
    formData.append('website', form.getFieldValue('website'));
    formData.append('link', form.getFieldValue('link'));
    formData.append('conditions', form.getFieldValue('conditions'));

    try {

      const formResponse = await  axios.post('https://hooks.zapier.com/hooks/catch/1247261/3ydkbln/', formData);

      setLoadingState(false);
      setSuccessState(true);

    } catch (error) {
      setErrorState(true);
    }
  };

  const onChange = (e: CheckboxChangeEvent) => {
    form.setFieldValue('conditions', e.target.checked);
  };

  if (error) {
    return (
      <div className="apply-container">
        <div className="apply-content error">
          <h1>There was an error sending your form!</h1>
        </div>
      </div>);
  }

  if (success) {
    return (
      <div className="apply-container">
        <div className="apply-content success">
          <h1>Your form was sent!</h1>
        </div>
      </div>);
  }

  return (
    <>
      <div id="apply" className="apply-container">
        <div className="apply-content">
          <h1>Apply</h1>
          <div>
              <h3>Competition deadline:</h3>
              <span>Sunday, March 12th 2023</span>
          </div>
          <div>
              <h3>Entry requirements:</h3>
              <ul>
                  <li>- 5-20 full looks inspired by iconic Dolce&Gabbana prints</li>
                  <li>- Digital design in any format, and with any medium is accepted</li>
                  <li>- Link to the art portfolio containing the submissions (wetransfer, dropbox, google drive, etc.)</li>
              </ul>
          </div>
          <Form form={form}>
              <Form.Item name="firstName" rules={[{ required: true, message: "First name is required!" }]}>
                  <Input size="large" placeholder="First Name*"></Input>
              </Form.Item>
              <Form.Item name="lastName" rules={[{ required: true, message: "Last name is required!" }]}>
                  <Input size="large" placeholder="Last Name*"></Input>
              </Form.Item>
              <Form.Item name="email" rules={[{ required: true, message: "Email not valid!", pattern: new RegExp(EMAIL_REGEX), type: 'email' }]}>
                  <Input size="large" placeholder="Email*"></Input>
              </Form.Item>
              <Form.Item name="twitter">
                  <Input size="large" placeholder="Twitter"></Input>
              </Form.Item>
              <Form.Item name="instagram">
                  <Input size="large" placeholder="Instagram"></Input>
              </Form.Item>
              <Form.Item name="website">
                  <Input size="large" placeholder="Website"></Input>
              </Form.Item>
              <Form.Item name="link" rules={[{ required: true, message: "A link to your portfolio is required!" }]}>
                  <Input size="large" placeholder="Upload link to portfolio*"></Input>
              </Form.Item>
              <Form.Item name="conditions" valuePropName="checked" rules={[{ required: true, message: "You need to agree with the terms and conditions!" }]}>
                  <Checkbox onChange={onChange}>I agree with the <span className="terms-conditions" onClick={() => showModal()}>terms and conditions*</span></Checkbox>
              </Form.Item>
              <Form.Item shouldUpdate>
                  {() => (
                    <Button style={{ width: '100%' }} type="primary" size="large" onClick={() => sendForm()} disabled={!form.getFieldValue('firstName') || !form.getFieldValue('lastName') || !form.getFieldValue('email') || !form.getFieldValue('link') || !form.getFieldValue('conditions') || form.getFieldsError().filter(({ errors }) => errors.length).length > 0} loading={loading}>Submit</Button>
                  )}
              </Form.Item>
          </Form>
        </div>
        <div className="logos-container">
          <img src="../assets/img/dg-logo.png"/>
          <img src="../assets/img/unxd-logo.png"/>
          <img src="../assets/img/decentraland-logo.png"/>
        </div>
        <div className="rrss-container">
          <a href="https://twitter.com/unxd_nft" target="_blank" rel="noreferrer">
            <img src="../assets/img/twitter_black.png"/>
          </a>
          <a href="https://discord.unxd.com/invitation" target="_blank" rel="noreferrer">
            <img src="../assets/img/discord_black.png"/>
          </a>
          <a href="https://www.instagram.com/unxd_nft/" target="_blank" rel="noreferrer">
            <img src="../assets/img/instagram_black.png"/>
          </a>
        </div>
      </div>


      <Modal className="terms-conditions-modal" title="TERMS AND CONDITIONS" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <p>By registering for the Future Rewind Competition presented by Dolce&Gabbana and UNXD, I hereby agree to be bound by the following terms and conditions (the “Terms and Conditions”):
I shall fully participate in all Future Rewind Competition events and related programs and, upon reasonable request, will provide any additional images or materials required for use for the Future Rewind Competition and any accompanying public materials, exhibitions, or related programs. I acknowledge that opting out of any components of the Future Rewind program may be grounds for disqualification.</p>
          <p>UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana, shall have irrevocable, perpetual, exclusive permission to use and modify the materials I submit to the Future Rewind Competition (the “Materials”), in whole or in part, in any manner or media (whether now known or later developed) for purposes of the Future Rewind Competition, the subsequent digital collection, promotion thereof, and for all standard educational, scholarly, research, or archival uses.</p>
          <p>UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana shall have perpetual and irrevocable permission to use without charge my name, likeness, image(s), and video(s) in connection with the Future Rewind Competition, promotion thereof, and for all standard educational, research, or archival uses.</p>
          <p>UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana will not return my Materials but instead may keep my Materials as part of the archives of the Future Rewind Competition.</p>
          <p><strong>Representation and Warranty:</strong> I represent and warrant that I own all rights to the Materials I submit or that I have obtained all permissions necessary from the respective owners to give the Future Rewind Competition the rights described above. Upon request, I promptly shall provide documentation of such permissions to the Future Rewind Competition. Failure to promptly provide such documentation may result in my disqualification at the sole discretion of the Future Rewind Competition.</p>
          <p><strong>Confidentiality:</strong> Information about my candidacy, my submission of the Materials, and any information disclosed to me about the Future Rewind Competition candidacy, registrations, candidate submissions, judging, and related processes, is proprietary to the Future Rewind Competition. I shall not disclose such information to any third party without prior written permission from UNXD or Dolce&Gabbana.</p>
          <p><strong>Publicity Release:</strong> UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana may photograph, film, or otherwise record my participation, performance, statements, and any of my Materials that appear in the Future Rewind Competition and related programs. The aforementioned parties may use such film or recording in any manner or medium (whether now known or later discovered) without charge. I disclaim any interest or rights I may have in connection with my appearance, participation, or statements for the Future Rewind Competition and related programs, as filmed or recorded or edited by UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana. The aforementioned parties are under no obligation to film or record me or my Materials or to use such film or record if made.</p>
          <p><strong>Liability Release:</strong> I irrevocably release and discharge UNXD and Dolce&Gabbana, and any associated companies, affiliates, or partners of the aforementioned from any liability or responsibility for any loss or damage to my Materials or any loss or damage that occurs as a result of my participation in the Future Rewind Competition.</p>
          <p><strong>Indemnity:</strong> I irrevocably indemnify and hold harmless UNXD and Dolce&Gabbana, and its collective affiliates, regents, trustees, officers, jury, employees, agents, and partners from and against all claims, demands, suits, judgments, and expenses, including reasonable out-of-pocket attorneys’ fees, arising out of my failure to comply with this Agreement or otherwise from my participation in the Future Rewind Competition.</p>
          <p><strong>Miscellaneous:</strong> UNXD and Dolce&Gabbana have no obligation to select me or my submission as a Finalist or a winner. UNXD and Dolce&Gabbana, and those authorized by UNXD or Dolce&Gabbana have the right but not the obligation to use my Materials, name, likeness, image, or participation in any manner. UNXD and Dolce&Gabbana may cancel or make adjustments to the Future Rewind Competition at any time and at its sole discretion. All decisions from UNXD and Dolce&Gabbana are final and binding.</p>
          <p>Any/all proprietary information provided to the Future Rewind Competition will be kept strictly confidential and will be specifically used in connection with the review of your application. The composition and financial amount of any associated prize will be made in the sole discretion of the Future Rewind Competition.</p>
          <p><strong>Agreement*</strong> I have read and agreed to the foregoing Terms and Conditions. I agree that the information contained in this application is true and correct to the best of my knowledge. I further certify that I, the undersigned applicant, have personally completed this application.</p>
      </Modal>
    </>
  );
}

export default Apply;