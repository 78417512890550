import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// MODULES
import Header from './shared/Header';
import Home from './Home';
import Footer from './shared/Footer';

// STYLES
import './App.scss';

function App() {
  return (
    <div className="app-container">
      <Header/>
      <Home/>
      <Footer/>
    </div>
  );
}

export default App;
