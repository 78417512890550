import React from 'react';

// ANTD
import Button from 'antd/lib/button';

// STYLES
import './styles.scss';

function Header() {

    const applyNow = (): void => {
        document.getElementById('apply')?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <div className="header-container">
            <div className="header-content">
                <a href="/">
                    <img src="../../assets/img/logo_dark.png" width="111px" alt="UNXD Logo" />
                </a>
                <ul>
                    <li><a href="https://unxd.com/drops" className="underline-animation underline-animation--menu">Drops</a></li>
                    <li><a href="https://unxd.com/marketplace" className="underline-animation underline-animation--menu">Marketplace</a></li>
                </ul>
                <div></div>
                <Button type="primary" className="connect" onClick={() => applyNow()}>Apply now</Button>
            </div>
        </div>
    );

}

export default Header;